<template>
  <router-link :to="back_to">
    <button
      :class="[$route.name == 'Menu' ? 'back-menu' : '', 'icon', 'back']"
    ></button>
  </router-link>
</template>
<script>
export default {
  props: {
    back_to: "",
  },
};
</script>
<style scoped>
.back {
  background-image: url("../../assets/common/left.svg");
  width: 24px;
  height: 14px;
}

.back-menu {
  background-size: 30px;
  width: 30px;
  height: 30px;
}
</style>