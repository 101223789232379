<template>
  <div class="modal" id="privacy_modal">
    <div class="content">
      <div class="head">
        <button
          @click="closeModal('privacy_modal')"
          class="icon close"
        ></button>
        <div>{{ $t("settings.privacy_policy") }}</div>
        <span></span>
      </div>
      <div class="body">
        <!-- privacy statment -->
        <div class="privacy">
          <h3>
            {{ $t("privacy_policy.title") }}
          </h3>

          <div class="rule">
            <h4>
              {{ $t("privacy_policy.registrar.title") }}
            </h4>
            <div class="detail">
              <div class="key">
                {{ $t("privacy_policy.person_in_charge.company.key") }}
              </div>
              <div class="value">
                {{ $t("privacy_policy.person_in_charge.company.val") }}
              </div>
            </div>
            <div class="detail">
              <div class="key">
                {{ $t("privacy_policy.person_in_charge.name.key") }}
              </div>
              <div class="value">
                {{ $t("privacy_policy.person_in_charge.name.val") }}
              </div>
            </div>

            <div class="detail">
              <div class="key">
                {{ $t("privacy_policy.person_in_charge.address.key") }}
              </div>
              <div class="value">
                {{ $t("privacy_policy.person_in_charge.address.val") }}
              </div>
            </div>
            <div class="detail">
              <div class="key">
                {{ $t("privacy_policy.person_in_charge.zip_code.key") }}
              </div>
              <div class="value">
                {{ $t("privacy_policy.person_in_charge.zip_code.val") }}
              </div>
            </div>
            <div class="detail">
              <div class="key">
                {{ $t("privacy_policy.person_in_charge.postal_district.key") }}
              </div>
              <div class="value">
                {{ $t("privacy_policy.person_in_charge.postal_district.val") }}
              </div>
            </div>
            <div class="detail">
              <div class="key">
                {{ $t("privacy_policy.person_in_charge.phone.key") }}
              </div>
              <div class="value">
                {{ $t("privacy_policy.person_in_charge.phone.val") }}
              </div>
            </div>
            <div class="detail">
              <div class="key">
                {{ $t("privacy_policy.person_in_charge.email.key") }}
              </div>
              <div class="value">
                {{ $t("privacy_policy.person_in_charge.email.val") }}
              </div>
            </div>
          </div>
          <div class="rule">
            <h4>
              {{ $t("privacy_policy.person_in_charge.title") }}
            </h4>
            <div class="detail">
              <div class="key">
                {{ $t("privacy_policy.registrar.name.key") }}
              </div>
              <div class="value">
                {{ $t("privacy_policy.registrar.name.val") }}
              </div>
            </div>
            <div class="detail">
              <div class="key">
                {{ $t("privacy_policy.registrar.business_id.key") }}
              </div>
              <div class="value">
                {{ $t("privacy_policy.registrar.business_id.val") }}
              </div>
            </div>
            <div class="detail">
              <div class="key">
                {{ $t("privacy_policy.registrar.address.key") }}
              </div>
              <div class="value">
                {{ $t("privacy_policy.registrar.address.val") }}
              </div>
            </div>
            <div class="detail">
              <div class="key">
                {{ $t("privacy_policy.registrar.zip_code.key") }}
              </div>
              <div class="value">
                {{ $t("privacy_policy.registrar.zip_code.val") }}
              </div>
            </div>
            <div class="detail">
              <div class="key">
                {{ $t("privacy_policy.registrar.postal_district.key") }}
              </div>
              <div class="value">
                {{ $t("privacy_policy.registrar.postal_district.val") }}
              </div>
            </div>
            <div class="detail">
              <div class="key">
                {{ $t("privacy_policy.registrar.phone.key") }}
              </div>
              <div class="value">
                {{ $t("privacy_policy.registrar.phone.val") }}
              </div>
            </div>
            <div class="detail">
              <div class="key">
                {{ $t("privacy_policy.registrar.email.key") }}
              </div>
              <div class="value">
                {{ $t("privacy_policy.registrar.email.val") }}
              </div>
            </div>
          </div>
          <div class="rule">
            <h4>
              {{ $t("privacy_policy.data_protection.title") }}
            </h4>
            <div class="detail">
              <div class="key">
                {{ $t("privacy_policy.person_in_charge.company.key") }}
              </div>
              <div class="value">
                {{ $t("privacy_policy.person_in_charge.company.val") }}
              </div>
            </div>
            <div class="detail">
              <div class="key">
                {{ $t("privacy_policy.person_in_charge.name.key") }}
              </div>
              <div class="value">
                {{ $t("privacy_policy.person_in_charge.name.val") }}
              </div>
            </div>

            <div class="detail">
              <div class="key">
                {{ $t("privacy_policy.person_in_charge.address.key") }}
              </div>
              <div class="value">
                {{ $t("privacy_policy.person_in_charge.address.val") }}
              </div>
            </div>
            <div class="detail">
              <div class="key">
                {{ $t("privacy_policy.person_in_charge.zip_code.key") }}
              </div>
              <div class="value">
                {{ $t("privacy_policy.person_in_charge.zip_code.val") }}
              </div>
            </div>
            <div class="detail">
              <div class="key">
                {{ $t("privacy_policy.person_in_charge.postal_district.key") }}
              </div>
              <div class="value">
                {{ $t("privacy_policy.person_in_charge.postal_district.val") }}
              </div>
            </div>
            <div class="detail">
              <div class="key">
                {{ $t("privacy_policy.person_in_charge.phone.key") }}
              </div>
              <div class="value">
                {{ $t("privacy_policy.person_in_charge.phone.val") }}
              </div>
            </div>
            <div class="detail">
              <div class="key">
                {{ $t("privacy_policy.person_in_charge.email.key") }}
              </div>
              <div class="value">
                {{ $t("privacy_policy.person_in_charge.email.val") }}
              </div>
            </div>
          </div>
          <div class="rule">
            <h4>
              {{ $t("privacy_policy.purpose.title") }}
            </h4>
            <p>{{ $t("privacy_policy.purpose.first_line") }}</p>
            <p>{{ $t("privacy_policy.purpose.body") }}</p>
          </div>
          <div class="rule">
            <h4>
              {{ $t("privacy_policy.data_collection.title") }}
            </h4>
            <p>{{ $t("privacy_policy.data_collection.body") }}</p>
          </div>
          <div class="rule">
            <h4>
              {{ $t("privacy_policy.information.title") }}
            </h4>
            <p>{{ $t("privacy_policy.information.body") }}</p>
          </div>
          <div class="rule">
            <h4>
              {{ $t("privacy_policy.retention.title") }}
            </h4>
            <p>{{ $t("privacy_policy.retention.body") }}</p>
          </div>
          <div class="rule">
            <h4>
              {{ $t("privacy_policy.sources.title") }}
            </h4>
            <p>{{ $t("privacy_policy.sources.first_line") }}</p>
            <p>{{ $t("privacy_policy.sources.body") }}</p>
          </div>
          <div class="rule">
            <h4>
              {{ $t("privacy_policy.data_transfer.title") }}
            </h4>
            <p>{{ $t("privacy_policy.data_transfer.body") }}</p>
          </div>
          <div class="rule">
            <h4>
              {{ $t("privacy_policy.cookies.title") }}
            </h4>
            <p>{{ $t("privacy_policy.cookies.body") }}</p>
          </div>
          <div class="rule">
            <h4>
              {{ $t("privacy_policy.security.title") }}
            </h4>
            <p>{{ $t("privacy_policy.security.body") }}</p>
          </div>
          <div class="rule">
            <h4>
              {{ $t("privacy_policy.decision.title") }}
            </h4>
            <p>{{ $t("privacy_policy.decision.body") }}</p>
          </div>
          <div class="rule">
            <h4>
              {{ $t("privacy_policy.rights.title") }}
            </h4>
            <p>{{ $t("privacy_policy.rights.body") }}</p>
          </div>
        </div>
        <!-- terms of us -->
        <div class="terms">
          <h3>
            {{ $t("terms.title") }}
          </h3>
          <div class="rule">
            <h4>{{ $t("terms.provider.title") }}</h4>
            <p>{{ $t("terms.provider.body") }}</p>
          </div>
          <div class="rule">
            <h4>{{ $t("terms.conditions.title") }}</h4>
            <p>{{ $t("terms.conditions.body") }}</p>
          </div>
          <div class="rule">
            <h4>{{ $t("terms.policy.title") }}</h4>
            <p>{{ $t("terms.policy.body") }}</p>
          </div>
          <div class="rule">
            <h4>{{ $t("terms.availability.title") }}</h4>
            <p>{{ $t("terms.availability.body") }}</p>
          </div>
          <div class="rule">
            <h4>{{ $t("terms.responsibility.title") }}</h4>
            <p>{{ $t("terms.responsibility.body") }}</p>
          </div>
          <div class="rule">
            <h4>{{ $t("terms.rights.title") }}</h4>
            <p>{{ $t("terms.rights.body") }}</p>
          </div>
          <div class="rule">
            <h4>{{ $t("terms.links.title") }}</h4>
            <p>{{ $t("terms.links.body") }}</p>
          </div>
          <div class="rule">
            <h4>{{ $t("terms.resources.title") }}</h4>
            <p>{{ $t("terms.resources.body") }}</p>
          </div>
          <div class="rule">
            <h4>{{ $t("terms.security.title") }}</h4>
            <p>{{ $t("terms.security.body") }}</p>
          </div>
          <div class="rule">
            <h4>{{ $t("terms.liability.title") }}</h4>
            <p>{{ $t("terms.liability.body") }}</p>
          </div>
          <div class="rule">
            <h4>{{ $t("terms.law.title") }}</h4>
            <p>{{ $t("terms.law.body") }}</p>
          </div>
          <div class="rule">
            <h4>{{ $t("terms.termination.title") }}</h4>
            <p>{{ $t("terms.termination.body") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.modal {
  padding: 0;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #4d4d4d;
  width: 100%;
  border-bottom: 1px solid #f0edf2;
  padding: 12px 0;
}

.content {
  background-color: #ffffff;
  height: 100vh;
  padding: 10px;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}
.body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  height: 85vh;
  width: 100%;
  overflow-y: scroll;
}
.terms,
.privacy {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.close {
  background-image: url("../../assets/common/close.svg");
  height: 28px;
  width: 28px;
}
</style>