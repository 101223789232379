<template>
  <div class="modal" id="about_modal">
    <div class="content">
      <div class="head">
        <button @click="closeModal('about_modal')" class="icon close"></button>
        <div>{{ $t("settings.about") }}</div>
        <span></span>
      </div>
      <div class="body">
        <center class="bold">
          Vuodesta 2019 lähtien Nordantia on tarjonnut palveluita suurille
          suomalaisille IT-yrityksille ravintola- ja kahvilapalveluiden osalta.
          Yrityksen nykyinen palvelu tarjoaa apua kaikkien ravintoloiden
          sisäisiin ja ulkoisiin toimenpiteisiin.
        </center>
        <img src="../../assets/common/map.png" width="240" height="440" />
        <center>
          Since 2019, Nordantia Oy is providing services to large Finnish
          companies in the field of IT with a sharp focus on restaurant and
          cafeteria services. The public product of the company is serving
          public restaurants and covers indoor/outdoor orders and activities.
        </center>
        <p class="bold">
          Ravintolat tekevät raskaan työn valmistaessaan ja tarjoillessaan
          ruokaa, joten he ansaitsevat parempaa! Älä maksa epäreiluja
          komissioita, vaan ota hallinta tilauksista itsellesi!
          Online-tilaukset, pöytävaraukset, sosiaalinen media ja paljon muuta
          samassa sovelluksessa.
        </p>
        <p>
          Restaurants do the heavy work in preparing and serving food, so they
          deserve better! Don’t pay unfair commissions and take control of your
          incoming orders! Online orders, table reservations, all social medias
          and many more in one app.
        </p>
        <p class="bold">
          Nordantian nykyinen palvelu luo ravintoloille yhteisön, jonka kautta
          ne voivat kommunikoida asiakkaiden kanssa ja hallita heidän pyyntöjään
          mahdollisimman helposti. Sovellus näyttää ravintolan omalta
          sovellukselta, joten oman sovelluksen kehitykseen ei ole tarvetta.
        </p>
        <p>
          Nordantia public product is establishing a community of restaurants to
          communicate with their users as easy as possible and manage their
          requests properly. The App looks like restaurants own app – No need to
          develop your own app.
        </p>
        <div class="screens">
          <img src="../../assets/common/screen1.png" width="240" height="440" />
          <img src="../../assets/common/screen2.png" width="240" height="440" />
          <img src="../../assets/common/screen3.png" width="240" height="440" />
        </div>
        <div class="contact">
          <h3 class="bold">Ota yhteyttä, jos haluat tietää lisää:</h3>
          <a class="bold" href="tel:0413147604">0413147604</a>
          <a class="bold" href="mailto:service@nordantia.com"
            >service@nordantia.com</a
          >
        </div>
        <div class="contact">
          <h3>Contact us if you need to know more:</h3>
          <a href="tel:0413147604">0413147604</a>
          <a href="mailto:service@nordantia.com">service@nordantia.com</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.modal {
  padding: 0;
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #4d4d4d;
  width: 100%;
  border-bottom: 1px solid #f0edf2;
  padding: 12px 0;
}

.content {
  background-color: #ffffff;
  height: 100vh;
  padding: 10px;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
}
.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  height: 75vh;
  width: 100%;
  overflow-y: scroll;
}
.screens {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  align-items: center;
}
.bold {
  font-weight: bold;
}
.contact {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  align-items: flex-start;
}
a {
  color: #4d4d4d;
  text-decoration: underline;
}
.close {
  background-image: url("../../assets/common/close.svg");
  height: 28px;
  width: 28px;
}
</style>